class MatrizService {
  constructor() {}
  resources = () => ({
    listarProyeccionMO: {
      uri: `/v1/presupuesto/listar-proyeccion-mo`,
      method: ['get']
    },
    exportarProyeccionMO: {
      uri: `/v1/exportarproyeccionmo`,
      method: ['get']
    },
    exportarModeloProyeccionMO: {
      uri: `/v1/exportarformatoproyeccionmo`,
      method: ['get']
    },
    importarProyeccionMO: {
      uri: `/v1/presupuesto/importar-proyeccion-mo`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
